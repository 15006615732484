<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">500</h1>
            <h4 class="pt-3">Houston, we have a problem!</h4>
            <p class="text-muted">The page you are looking for is temporarily unavailable.</p>
          </div>
          <CInput
            class="mb-3"
            placeholder="What are you looking for?"
          >
            <template #prepend-content>
              <CIcon name="cil-magnifying-glass"/>
            </template>
            <template #append>
              <CButton color="info">Search</CButton>
            </template>
          </CInput>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Page500'
}
</script>
